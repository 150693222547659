@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
$font_poppins:'Poppins', sans-serif;
$font_lato:'Lato', sans-serif;
$primary-color:#856BD3;
$body_clr:#F1EDFF;
$black_clr:#000000;
$text_clr:#666666;
$white-clr:#ffffff;
$black-clr1:#303030;
@mixin important-text {
    width: 100%;
    height: auto;
    float: left;
    position: relative;
}
body {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    background: $body_clr;
    font-family: $font_poppins;
}
h1,h2,h3,h4, h5,p{
    margin: 0;
}
h3{
        margin-bottom: 5px;
}
ul{
    padding: 0;
    margin: 0;
    li{
        list-style: none;
    }
}
.container {
    max-width: 90%;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
}
figure{
    margin: 0;
}
form{
        display: flex;
        flex-flow: wrap column;
        align-items: center;
        justify-content: center;
        gap: 25px;
        padding-top: 60px;
        .field{
            @include important-text();
            display: flex;
            align-items: center;
            // justify-content: center;
            flex-wrap: wrap;
        .form-control{
            width: 100%;
            margin: 0 auto;
            background: linear-gradient(135deg, #FFFFFF 0%, #EDEDED 100%);
            box-shadow: 2px 4px 6px 2px rgba(223, 211, 255, 0.4);
            border-radius: 50px;
            outline: none;
            padding: 15px 20px;
            border: none;
            @extend %paragraph-text;
            font-size: 15px;
            font-family: $font_poppins;
            // text-transform: capitalize;
            
        }
        textarea {
        border-radius: 10px!important;
        }
      
             
         i {          
            position: absolute;
              right: 20px;
              top: 20px;
            color: #acacac;
            
        }
        
        
    }
   
        .btn-link{
            margin-top: 20px;
        }
        .CheckBox {
        width: 100%;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        @extend %paragraph-text;
        .signRemainder {
          color: $text-clr;

          input[type="checkbox"] {
            position: relative;
            cursor: pointer;
            margin-right: 15px;
            font-weight: 500;
            font-size: 15px;
          }
          input[type="checkbox"]:before {
            content: "";
            display: block;
            position: absolute;
            width: 18px;
            height: 18px;
            top: -2px;
            left: 0;   
            background: #F5F5F5;
            border: 1px solid rgba(147, 118, 244, 0.47);
            border-radius: 2px;
          }
          input[type="checkbox"]:checked:after {
            content: "";
            display: block;
            width: 5px;
            height: 10px;
            border: solid $primary-color;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 1px;
            left: 6px;
          }
        }
        p {
          color: $primary-color;
          font-weight: 500;
          font-size: 15px;
          a{
          color: $primary-color;
          font-weight: 500;
          font-size: 15px;
          text-decoration: none;
          }
        }
      }
      .text {
          text-align: center;
          @extend %paragraph-text;
          font-weight: 500;
          font-size: 15px;
          color: $text_clr;
          padding-bottom: 30px;
          a {
            color: $primary-color;
            text-transform: capitalize;
            text-decoration: none;
            padding-left: 2px;
          }        
        }
          .formBtn{
          background: transparent;
          color: $primary-color;
          text-transform: capitalize;
          outline: none;
          border: none;
          transition: 0.3s;
          @extend %paragraph-text;
          font-weight: 500;
        //   &:hover{
        //     color: $secondary-color;
        //   }
        }
        .otherAccount{
            @include important-text();
            ul{
                display: flex;
                gap: 10px;
               
            }
        }
        }
%page-heading {
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: $font_poppins;
}
%paragraph-text{
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    font-family: $font_poppins;
}
.btn-link {
    color: $white-clr;
    background: linear-gradient(180deg, #A68EF9 0%, #856BD3 100%);
    box-shadow: 0px 4px 18px 4px rgba(147, 118, 244, 0.23);
    border-radius: 50px;   
    background-size: 100% 100%;   
    padding: 8px 45px;
    text-transform: capitalize;
    transition: all 0.4s ease-in-out;
    font-size: 15px;
    font-weight: 600;
    outline: none;   
    text-decoration: none !important;
    display: inline-block;
    vertical-align: bottom;
    line-height: 30px;
    outline: none;
    border: none;
    cursor: pointer;
    &:hover {
        background-position: 0 100%;
        background-size: 100% 300%;
        transition: all 0.4s ease-in-out;
        color: $white-clr;
        text-decoration: none !important;
    }
    &:active {
        background-position: 0 100%;
        background-size: 100% 200%;
        color: $white-clr;
        text-decoration: none !important;
        transition: all 0.5s ease-in-out;
    }
    &:focus {
        background-position: 0 100%;
        background-size: 100% 200%;
        color: $white-clr;
        text-decoration: none !important;
        transition: all 0.5s ease-in-out;
    }


}
/**********splashBg*************/
.splashBg{
    @include important-text();
    background: url(../src/Images/SplashScreenbg.png) no-repeat center;
    background-size: cover;
    height: 100vh;
    a{
    display: inline-block;
    width: 100%;
    height: 100%;
    }
    .logo{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        img{
            width: 250px;
        }
    }
}
/***********Login_sec************/
.Login_sec{
    @include important-text();
    .sideImg{
        img{
            position: absolute;
            width: 80%;
            top: 0;
            left: 0;
        }
    }   

    .headSec{
        @include important-text();
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-top: 190px;
        h3{
            @extend %page-heading;
            color: $black_clr;
            margin: 0;
        }
        p{
            @extend %paragraph-text;
            color: $text_clr;
            text-align: center;
        }
    }
    .loginForm{
        @include important-text();
    //     form{
    //     display: flex;
    //     flex-flow: wrap column;
    //     align-items: center;
    //     justify-content: center;
    //     gap: 25px;
    //     padding-top: 60px;
    //     .field{
    //         @include important-text();
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //     .form-control{
    //         width: 90%;
    //         margin: 0 auto;
    //         background: linear-gradient(135deg, #FFFFFF 0%, #EDEDED 100%);
    //         box-shadow: 2px 4px 6px 2px rgba(223, 211, 255, 0.4);
    //         border-radius: 50px;
    //         outline: none;
    //         padding: 15px 20px;
    //         border: none;
            
    //     }
    // }
   
    //     .btn-link{
    //         margin-top: 10px;
    //     }
    //     .CheckBox {
    //     width: 100%;
    //     color: #000000;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     margin-bottom: 50px;
    //     @extend %paragraph-text;
    //     .signRemainder {
    //       color: $text-clr;

    //       input[type="checkbox"] {
    //         position: relative;
    //         cursor: pointer;
    //         margin-right: 15px;
    //         font-weight: 500;
    //         font-size: 15px;
    //       }
    //       input[type="checkbox"]:before {
    //         content: "";
    //         display: block;
    //         position: absolute;
    //         width: 18px;
    //         height: 18px;
    //         top: -2px;
    //         left: 0;   
    //         background: #F5F5F5;
    //         border: 1px solid rgba(147, 118, 244, 0.47);
    //         border-radius: 2px;
    //       }
    //       input[type="checkbox"]:checked:after {
    //         content: "";
    //         display: block;
    //         width: 5px;
    //         height: 10px;
    //         border: solid $primary-color;
    //         border-width: 0 2px 2px 0;
    //         -webkit-transform: rotate(45deg);
    //         -ms-transform: rotate(45deg);
    //         transform: rotate(45deg);
    //         position: absolute;
    //         top: 2px;
    //         left: 6px;
    //       }
    //     }
    //     p {
    //       color: $primary-color;
    //       font-weight: 500;
    //       font-size: 15px;
    //     }
    //   }
    //   .text {
    //       text-align: center;
    //       @extend %paragraph-text;
    //       font-weight: 500;
    //       font-size: 15px;
    //       color: $text_clr;
    //       padding-bottom: 30px;
    //       a {
    //         color: $primary-color;
    //         text-transform: capitalize;
    //         text-decoration: none;
    //       }        
    //     }
    //       .formBtn{
    //       background: transparent;
    //       color: $primary-color;
    //       text-transform: capitalize;
    //       outline: none;
    //       border: none;
    //       transition: 0.3s;
    //       @extend %paragraph-text;
    //       font-weight: 500;
    //     //   &:hover{
    //     //     color: $secondary-color;
    //     //   }
    //     }
    //     .otherAccount{
    //         @include important-text();
    //         ul{
    //             display: flex;
    //             gap: 10px;
               
    //         }
    //     }
    //     }
    .btn-link{
    display: flex;
    align-items: center;
    justify-content: center;
    .loader{
        margin-left:10px;
        position: relative;
        img{
            width: 25px;
            margin-top: 5px;
        }
    }
    }
    .disable{
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events:none;

    }
    }
    
}
.signUpSec{
    p{
        @extend %paragraph-text;
        color: $text_clr;
        font-family: $font_poppins;
        font-weight: 500;
        text-align: left;
        width: 100%;
       
    }
}


/*************serviceSec***********/
.serviceSec{
    @include important-text();
    background: linear-gradient(180deg, #A68EF9 0%, #856BD3 100%);
    .heading{
        @include important-text();
        padding: 80px 0 80px;
        width: 90%;
        margin-left: 5%;
        h3{
            @extend %page-heading;
            color: $white-clr;

        }
        p{
            @extend %paragraph-text;
            color: $white-clr;
        }
    }
    .innerSec{
        @include important-text();
        height: 100%;
        background: $body_clr;
        padding: 60px 0 60px;
        border-radius: 35px 35px 0px 0px;
        .boxListing{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: row wrap;
            gap: 23px;
            width: 80%;
            margin: 0 auto;
            text-align: center;
             .box{
                a{                    
                    text-decoration: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-flow: column;
                }
            width: 42%;
            background: linear-gradient(135deg, #FFFFFF 0%, #F7F7F7 100%);
            box-shadow: 2px 4px 6px 2px rgba(223, 211, 255, 0.6);
            border-radius: 11.3269px;
            padding: 20px;
            display: flex;            
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 120px;
            figure{
                width: 45px;
                height: 45px;                
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            p{
                @extend %paragraph-text;
                color: $text_clr;
                font-size: 12px;
                line-height: 20px;
                margin-top: 5px;
            }
        }
        }
      

    }
}

/**************serviceList**********/
.serviceList{
     @include important-text();
    background: linear-gradient(180deg, #A68EF9 0%, #856BD3 100%);
    .backBtn{
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: 99;
        a{
            text-decoration: none;
        }
       
            i{  
                background: rgba(133, 107, 211, 0.6);
                border-radius: 6px;
                width: 30px;
                height: 30px;
                border-radius: 6px;
                display: block;
                color:#FFF0F0;
                // padding: 6px 8px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        
    }
    .heading{
        @include important-text();
        padding: 80px 0 80px;
        width: 90%;
        margin-left: 5%;
        h3{
            @extend %page-heading;
            color: $white-clr;
            margin-bottom: 0;
            font-size: 24px;
            padding: 10px 0;

        }
        p{
            @extend %paragraph-text;
            color: $white-clr;
        }
    }
    .innerSec{
        @include important-text();
        height: 100%;
        background: $body_clr;
        padding: 60px 0 60px;
        border-radius: 35px 35px 0px 0px;
        .boxListing{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: row wrap;
            gap: 23px;
            width: 80%;
            margin: 0 auto;
            text-align: center;
             .box{
              
            width: 100%;
            background: linear-gradient(135deg, #FFFFFF 0%, #F7F7F7 100%);
            box-shadow: 2px 4px 6px 2px rgba(223, 211, 255, 0.6);
            border-radius: 11.3269px;
            padding: 10px;
            display: flex;            
            align-items: center;
            gap: 20px;
            justify-content: flex-start;
            transition: all 0.3s ease-in-out;
              
                    &:hover{
                        box-shadow: -2px 4.53077px 24.8px 4px #DFD3FF;
                    }
             a{  
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content:flex-start;
                    flex-flow: row wrap;
                    text-decoration: none;
                  
                
            figure{
                width: 45px;
                height: 45px;
                margin-right: 20px;
                background: #F5F5F5;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 85%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
            p{
                @extend %paragraph-text;
                font-size: 15px;
                color: #303030;
            }
        }
        }
       

    }

}
/*************serviceListInr**********/
.serviceListInr{
    @include important-text();
    .heading{
        padding: 60px 0 60px;
        h3{
            text-align: center;
            text-transform: capitalize;
        }
    }
    h3{
        margin-bottom: 20px;
        text-transform: capitalize;
        text-align: center;
    }
    .headImg{
        @include important-text();
        figure{
            width: 100%;
            height: 295px;
              img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
        }
      
    }
    .innerSec{
        margin-top: -40px;
        padding: 40px 0 60px;
        h4{
            @extend %page-heading;
            color: $black_clr1;
            text-align: center;
            font-family: $font_poppins;
            font-size: 24px;
            margin-bottom: 30px
        }
        p{
            @extend %paragraph-text;
            color: $text_clr;
            font-family: $font_poppins;
        }
        .btn-enquiry{
            background: #FFFFFF;
            box-shadow: 0px 1px 4px 1px rgba(223, 211, 255, 0.4);
            border-radius: 15px;
            display: block;
            width: 90%;
            margin: 0 auto;
            font-size: 15px;
            font-weight: 600;
            color: $primary-color;
            padding: 15px 10px;
            text-decoration: none;
            text-transform: capitalize;
            text-align: center;
            margin-top: 60px;
        }
    }
}
/***********Enquiry*************/
.enquirySec{
    @include important-text();
    .heading{
        padding: 60px 0 60px;
        text-align: center;
    }
    form{
        padding: 0!important;
    }
}

/********successPopUp**********/
.successPopUp{
    position:fixed;
    width: 100%;
    height: 100vh;
    background: $body_clr;
    top: 0;
    left: 0;
    // opacity: 0;
    // transform: scale(0);
    .successMsg{
        width: 70%;
        margin: 0 auto;
        background: linear-gradient(135deg, #FFFFFF 0%, #EDEDED 100%);
        box-shadow: 2px 4px 6px 2px rgba(223, 211, 255, 0.6);
        border-radius: 10px;
        position: relative;
        top: 0%;
        transform: translateY(-50%);
        padding: 16px;
        display: block;
        text-align: center;
        transition: 0.3s ease-in-out;
        opacity: 0;
        &.active {
            opacity: 1;
            top: 50%;
        }
        h4{
            font-size: 20px;
            font-weight: 500;
            color: #212121;
        }
        h5{
            font-size: 18px;
            font-size: 500;
            color: #008000;
        }
        p{
            @extend %paragraph-text;
            color: $text_clr;
            text-align: center;
            font-size: 13px;
            width: 85%;
            margin: 0 auto;
            padding: 10px 0;
        }
        .btn-link{
            margin-top: 10px;
            display: inline-block;
        }
    }
   
}
.noRecord{
    @include important-text();
    display: flex;
    align-items: center;
    justify-content: center;

    p{
    text-align: center;
    background: linear-gradient(135deg, #FFFFFF 0%, #F7F7F7 100%);
    box-shadow: 2px 4px 6px 2px rgb(223 211 255 / 60%);
    border-radius: 6px;
    width: 60%;
    margin: 0 auto;
    position: relative;
    float: left;
    padding: 20px 30px;
    }
}

.invalidInput{
    width: 90%;
    margin: 0 auto;
    display: block;
    color: #ff0000;
    @extend %paragraph-text;
    text-align: left;
    padding-top: 10px;
}
.form-error{
    width: 90%;
    margin: 0 auto;
    display: block;
    color: #ff0000!important;
    @extend %paragraph-text;
    text-align: left;
    padding-top: 10px;
}

.error-msg, .verifyAlert{
    position: fixed;
    left:0;
    bottom: 30px;
    right: 0;
    margin: 0 auto;
    width: 60%;
    text-align: center;
    background: #ff0000;
    box-shadow: 0px 4px 18px 4px rgb(255 0 0 / 23%);
    padding: 15px 20px;
    font-size: 12px;
    font-family: $font_poppins;
    color: #ffffff;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
    transform: scale(0);
    opacity: 0;
    &.active{
        opacity: 1;
        transform: scale(1);
    }
     
}
/**********forgotSec************/
.forgotSec{
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    right: 0;
    top: 0;
    bottom:0;
    background: $body_clr;
    .backBtn{
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: 99;
        a{
            text-decoration: none;
        }
       
            i{  
                background: rgba(133, 107, 211, 0.6);
                border-radius: 6px;
                width: 30px;
                height: 30px;
                border-radius: 6px;
                display: block;
                color:#FFF0F0;
                // padding: 6px 8px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        
    }
    .fotgotCnt{
    position: absolute;
    width: 80%;
    background: $white-clr;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    padding: 30px 20px;
   
    h3{
        text-align: center;
    }
    p{
        text-align: center;
        color: $text_clr;
        font-size: 12px;
    }
    form {
    padding-top: 30px;
    .field .form-control {
     background: linear-gradient(135deg, #FFFFFF 0%, #f7f7f7 100%);
    border: 1px solid #9279e2!important;
    padding: 12px 20px;
    }
    }
       .btn-link{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    .loader{
        margin-left:10px;
        position: relative;
        img{
            width: 25px;
            margin-top: 5px;
        }
    }
    }
    .disable{
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events:none;

    }
    }
}
.logout{
    .btn-logout{
        position: absolute;
        right: 20px;
        top: 20px;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 15px;
        font-family: $font_poppins;
        z-index: 9;
        background: $white-clr;
        outline: none;
        border: none;
        color: $primary-color;
        padding: 5px 12px;
        border-radius: 6px;
        border: 1px solid $white-clr;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &:hover{
            background: transparent;
            border: 1px solid $white-clr;
            color: $white-clr;

        }
    }
}

/*********responsive*************/

@media (min-width: 240px) and (max-width: 315px) { 
    .serviceSec .innerSec .boxListing .box {
    width: 50%!important;
    }
}
@media (min-width: 576px) and (max-width: 767.98px) { 
 form .field {
    width: 80%;
 }
 }
 // Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) { 
    form .field {
    width: 80%;
        
}
 }


.loaderSec{
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    z-index: 99;
    .loaderImg{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    }
}
 
* {
  box-sizing: border-box;
}


// .menu-container {
//   position: relative;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.menu-trigger {
  background: #ffffff;
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-left: auto; /* Strictly for positioning */
}

.menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}





.menu li {
  border-bottom: 1px solid #dddddd;
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}

.menu-container {
    position: relative;
    z-index: 9999;
    display: flex;
  .menuTrigger {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 6px;
    width: 40px;
    height: 40px;
    outline: none;
    border: 1px solid #8b76d6;
    background: $white-clr;
    cursor: pointer;
    i{
        color: $primary-color;
        font-size: 20px;
    }
}
.dropdownMenu{
    position: absolute;
    left: 0;
    right: 0;
    top: 90px;
    transform: translateY(-50%);
    width: 85%;
    margin: 0 auto;
    z-index: 99;
    background: $primary-color;
    padding: 30px 15px;
    border-radius: 5px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    ul{
      
        li{
            padding: 15px 0px;
            border-bottom: 1px solid #9176e4;
            text-align: center;
            &:last-child{
                border-bottom: 1px solid transparent;
            }
            a{
                font-size: 22px;
                font-weight: 500;
                text-decoration: none;
                cursor: pointer;
                text-transform: capitalize;
                color: #fff;
                text-align: center;
                letter-spacing: 1px;
            }
        }
    }
}
}
.dropdownMenu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: 0.3s ease;
}
.dropdownMenu.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50%);
  transition: 0.3s ease;
}

.success-Msg{
    position: fixed;
    left: 0;
    bottom: 30px;
    right: 0;
    margin: 0 auto;
    width: 60%;
    text-align: center;
    background: #008000;
    box-shadow: 0px 4px 18px 4px rgba(9, 255, 0, 0.23);
    padding: 15px 20px;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    color: #ffffff;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
    transform: scale(0);
    opacity: 0;
    &.active{
        opacity: 1;
        transform: scale(1);
    }
}
.btn-link{
    display: flex;
    align-items: center;
    justify-content: center;
    .loader{
        margin-left:10px;
        position: relative;
        img{
            width: 25px;
            margin-top: 5px;
        }
    }
}

.dropDownList{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255,255,255,0.8);
    width: 100%;
    height: 100vh;
    z-index: -1;
}
.dropDownList.active {
  opacity: 1;
  visibility: visible;
//   transform: translateY(0);
  transition: 0.3s ease;
}
.dropDownList.inactive {
  opacity: 0;
  visibility: hidden;
//   transform: translateY(1);
  transition: 0.3s ease;
}