@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap");
body {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  background: #F1EDFF;
  font-family: "Poppins", sans-serif;
}

h1, h2, h3, h4, h5, p {
  margin: 0;
}

h3 {
  margin-bottom: 5px;
}

ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}

.container {
  max-width: 90%;
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;
}

figure {
  margin: 0;
}

form {
  display: flex;
  flex-flow: wrap column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  padding-top: 60px;
}
form .field {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
form .field .form-control {
  width: 100%;
  margin: 0 auto;
  background: linear-gradient(135deg, #FFFFFF 0%, #EDEDED 100%);
  box-shadow: 2px 4px 6px 2px rgba(223, 211, 255, 0.4);
  border-radius: 50px;
  outline: none;
  padding: 15px 20px;
  border: none;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}
form .field textarea {
  border-radius: 10px !important;
}
form .field i {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #acacac;
}
form .btn-link {
  margin-top: 20px;
}
form .CheckBox {
  width: 100%;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
form .CheckBox .signRemainder {
  color: #666666;
}
form .CheckBox .signRemainder input[type=checkbox] {
  position: relative;
  cursor: pointer;
  margin-right: 15px;
  font-weight: 500;
  font-size: 15px;
}
form .CheckBox .signRemainder input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  top: -2px;
  left: 0;
  background: #F5F5F5;
  border: 1px solid rgba(147, 118, 244, 0.47);
  border-radius: 2px;
}
form .CheckBox .signRemainder input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid #856BD3;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 1px;
  left: 6px;
}
form .CheckBox p {
  color: #856BD3;
  font-weight: 500;
  font-size: 15px;
}
form .CheckBox p a {
  color: #856BD3;
  font-weight: 500;
  font-size: 15px;
  text-decoration: none;
}
form .text {
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  color: #666666;
  padding-bottom: 30px;
}
form .text a {
  color: #856BD3;
  text-transform: capitalize;
  text-decoration: none;
  padding-left: 2px;
}
form .formBtn {
  background: transparent;
  color: #856BD3;
  text-transform: capitalize;
  outline: none;
  border: none;
  transition: 0.3s;
  font-weight: 500;
}
form .otherAccount {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
}
form .otherAccount ul {
  display: flex;
  gap: 10px;
}

.serviceListInr .innerSec h4, .serviceList .heading h3, .serviceSec .heading h3, .Login_sec .headSec h3 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
}

.form-error, .invalidInput, .successPopUp .successMsg p, .serviceListInr .innerSec p, .serviceList .innerSec .boxListing .box p, .serviceList .heading p, .serviceSec .innerSec .boxListing .box p, .serviceSec .heading p, .signUpSec p, .Login_sec .headSec p, form .field .form-control, form .CheckBox, form .text, form .formBtn {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
}

.btn-link {
  color: #ffffff;
  background: linear-gradient(180deg, #A68EF9 0%, #856BD3 100%);
  box-shadow: 0px 4px 18px 4px rgba(147, 118, 244, 0.23);
  border-radius: 50px;
  background-size: 100% 100%;
  padding: 8px 45px;
  text-transform: capitalize;
  transition: all 0.4s ease-in-out;
  font-size: 15px;
  font-weight: 600;
  outline: none;
  text-decoration: none !important;
  display: inline-block;
  vertical-align: bottom;
  line-height: 30px;
  outline: none;
  border: none;
  cursor: pointer;
}
.btn-link:hover {
  background-position: 0 100%;
  background-size: 100% 300%;
  transition: all 0.4s ease-in-out;
  color: #ffffff;
  text-decoration: none !important;
}
.btn-link:active {
  background-position: 0 100%;
  background-size: 100% 200%;
  color: #ffffff;
  text-decoration: none !important;
  transition: all 0.5s ease-in-out;
}
.btn-link:focus {
  background-position: 0 100%;
  background-size: 100% 200%;
  color: #ffffff;
  text-decoration: none !important;
  transition: all 0.5s ease-in-out;
}

/**********splashBg*************/
.splashBg {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
  background: url(../src/Images/SplashScreenbg.png) no-repeat center;
  background-size: cover;
  height: 100vh;
}
.splashBg a {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.splashBg .logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.splashBg .logo img {
  width: 250px;
}

/***********Login_sec************/
.Login_sec {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
}
.Login_sec .sideImg img {
  position: absolute;
  width: 80%;
  top: 0;
  left: 0;
}
.Login_sec .headSec {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 190px;
}
.Login_sec .headSec h3 {
  color: #000000;
  margin: 0;
}
.Login_sec .headSec p {
  color: #666666;
  text-align: center;
}
.Login_sec .loginForm {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
}
.Login_sec .loginForm .btn-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Login_sec .loginForm .btn-link .loader {
  margin-left: 10px;
  position: relative;
}
.Login_sec .loginForm .btn-link .loader img {
  width: 25px;
  margin-top: 5px;
}
.Login_sec .loginForm .disable {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.signUpSec p {
  color: #666666;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: left;
  width: 100%;
}

/*************serviceSec***********/
.serviceSec {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
  background: linear-gradient(180deg, #A68EF9 0%, #856BD3 100%);
}
.serviceSec .heading {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
  padding: 80px 0 80px;
  width: 90%;
  margin-left: 5%;
}
.serviceSec .heading h3 {
  color: #ffffff;
}
.serviceSec .heading p {
  color: #ffffff;
}
.serviceSec .innerSec {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
  height: 100%;
  background: #F1EDFF;
  padding: 60px 0 60px;
  border-radius: 35px 35px 0px 0px;
}
.serviceSec .innerSec .boxListing {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  gap: 23px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.serviceSec .innerSec .boxListing .box {
  width: 42%;
  background: linear-gradient(135deg, #FFFFFF 0%, #F7F7F7 100%);
  box-shadow: 2px 4px 6px 2px rgba(223, 211, 255, 0.6);
  border-radius: 11.3269px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
}
.serviceSec .innerSec .boxListing .box a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.serviceSec .innerSec .boxListing .box figure {
  width: 45px;
  height: 45px;
}
.serviceSec .innerSec .boxListing .box figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.serviceSec .innerSec .boxListing .box p {
  color: #666666;
  font-size: 12px;
  line-height: 20px;
  margin-top: 5px;
}

/**************serviceList**********/
.serviceList {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
  background: linear-gradient(180deg, #A68EF9 0%, #856BD3 100%);
}
.serviceList .backBtn {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 99;
}
.serviceList .backBtn a {
  text-decoration: none;
}
.serviceList .backBtn i {
  background: rgba(133, 107, 211, 0.6);
  border-radius: 6px;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  display: block;
  color: #FFF0F0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.serviceList .heading {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
  padding: 80px 0 80px;
  width: 90%;
  margin-left: 5%;
}
.serviceList .heading h3 {
  color: #ffffff;
  margin-bottom: 0;
  font-size: 24px;
  padding: 10px 0;
}
.serviceList .heading p {
  color: #ffffff;
}
.serviceList .innerSec {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
  height: 100%;
  background: #F1EDFF;
  padding: 60px 0 60px;
  border-radius: 35px 35px 0px 0px;
}
.serviceList .innerSec .boxListing {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  gap: 23px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.serviceList .innerSec .boxListing .box {
  width: 100%;
  background: linear-gradient(135deg, #FFFFFF 0%, #F7F7F7 100%);
  box-shadow: 2px 4px 6px 2px rgba(223, 211, 255, 0.6);
  border-radius: 11.3269px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;
  transition: all 0.3s ease-in-out;
}
.serviceList .innerSec .boxListing .box:hover {
  box-shadow: -2px 4.53077px 24.8px 4px #DFD3FF;
}
.serviceList .innerSec .boxListing .box a {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  text-decoration: none;
}
.serviceList .innerSec .boxListing .box a figure {
  width: 45px;
  height: 45px;
  margin-right: 20px;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.serviceList .innerSec .boxListing .box a figure img {
  width: 85%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.serviceList .innerSec .boxListing .box p {
  font-size: 15px;
  color: #303030;
}

/*************serviceListInr**********/
.serviceListInr {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
}
.serviceListInr .heading {
  padding: 60px 0 60px;
}
.serviceListInr .heading h3 {
  text-align: center;
  text-transform: capitalize;
}
.serviceListInr h3 {
  margin-bottom: 20px;
  text-transform: capitalize;
  text-align: center;
}
.serviceListInr .headImg {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
}
.serviceListInr .headImg figure {
  width: 100%;
  height: 295px;
}
.serviceListInr .headImg figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.serviceListInr .innerSec {
  margin-top: -40px;
  padding: 40px 0 60px;
}
.serviceListInr .innerSec h4 {
  color: #303030;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  margin-bottom: 30px;
}
.serviceListInr .innerSec p {
  color: #666666;
  font-family: "Poppins", sans-serif;
}
.serviceListInr .innerSec .btn-enquiry {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px 1px rgba(223, 211, 255, 0.4);
  border-radius: 15px;
  display: block;
  width: 90%;
  margin: 0 auto;
  font-size: 15px;
  font-weight: 600;
  color: #856BD3;
  padding: 15px 10px;
  text-decoration: none;
  text-transform: capitalize;
  text-align: center;
  margin-top: 60px;
}

/***********Enquiry*************/
.enquirySec {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
}
.enquirySec .heading {
  padding: 60px 0 60px;
  text-align: center;
}
.enquirySec form {
  padding: 0 !important;
}

/********successPopUp**********/
.successPopUp {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #F1EDFF;
  top: 0;
  left: 0;
}
.successPopUp .successMsg {
  width: 70%;
  margin: 0 auto;
  background: linear-gradient(135deg, #FFFFFF 0%, #EDEDED 100%);
  box-shadow: 2px 4px 6px 2px rgba(223, 211, 255, 0.6);
  border-radius: 10px;
  position: relative;
  top: 0%;
  transform: translateY(-50%);
  padding: 16px;
  display: block;
  text-align: center;
  transition: 0.3s ease-in-out;
  opacity: 0;
}
.successPopUp .successMsg.active {
  opacity: 1;
  top: 50%;
}
.successPopUp .successMsg h4 {
  font-size: 20px;
  font-weight: 500;
  color: #212121;
}
.successPopUp .successMsg h5 {
  font-size: 18px;
  font-size: 500;
  color: #008000;
}
.successPopUp .successMsg p {
  color: #666666;
  text-align: center;
  font-size: 13px;
  width: 85%;
  margin: 0 auto;
  padding: 10px 0;
}
.successPopUp .successMsg .btn-link {
  margin-top: 10px;
  display: inline-block;
}

.noRecord {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noRecord p {
  text-align: center;
  background: linear-gradient(135deg, #FFFFFF 0%, #F7F7F7 100%);
  box-shadow: 2px 4px 6px 2px rgba(223, 211, 255, 0.6);
  border-radius: 6px;
  width: 60%;
  margin: 0 auto;
  position: relative;
  float: left;
  padding: 20px 30px;
}

.invalidInput {
  width: 90%;
  margin: 0 auto;
  display: block;
  color: #ff0000;
  text-align: left;
  padding-top: 10px;
}

.form-error {
  width: 90%;
  margin: 0 auto;
  display: block;
  color: #ff0000 !important;
  text-align: left;
  padding-top: 10px;
}

.error-msg, .verifyAlert {
  position: fixed;
  left: 0;
  bottom: 30px;
  right: 0;
  margin: 0 auto;
  width: 60%;
  text-align: center;
  background: #ff0000;
  box-shadow: 0px 4px 18px 4px rgba(255, 0, 0, 0.23);
  padding: 15px 20px;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  border-radius: 50px;
  transition: 0.3s ease-in-out;
  transform: scale(0);
  opacity: 0;
}
.error-msg.active, .verifyAlert.active {
  opacity: 1;
  transform: scale(1);
}

/**********forgotSec************/
.forgotSec {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #F1EDFF;
}
.forgotSec .backBtn {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 99;
}
.forgotSec .backBtn a {
  text-decoration: none;
}
.forgotSec .backBtn i {
  background: rgba(133, 107, 211, 0.6);
  border-radius: 6px;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  display: block;
  color: #FFF0F0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.forgotSec .fotgotCnt {
  position: absolute;
  width: 80%;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  padding: 30px 20px;
}
.forgotSec .fotgotCnt h3 {
  text-align: center;
}
.forgotSec .fotgotCnt p {
  text-align: center;
  color: #666666;
  font-size: 12px;
}
.forgotSec .fotgotCnt form {
  padding-top: 30px;
}
.forgotSec .fotgotCnt form .field .form-control {
  background: linear-gradient(135deg, #FFFFFF 0%, #f7f7f7 100%);
  border: 1px solid #9279e2 !important;
  padding: 12px 20px;
}
.forgotSec .fotgotCnt .btn-link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}
.forgotSec .fotgotCnt .btn-link .loader {
  margin-left: 10px;
  position: relative;
}
.forgotSec .fotgotCnt .btn-link .loader img {
  width: 25px;
  margin-top: 5px;
}
.forgotSec .fotgotCnt .disable {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.logout .btn-logout {
  position: absolute;
  right: 20px;
  top: 20px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  z-index: 9;
  background: #ffffff;
  outline: none;
  border: none;
  color: #856BD3;
  padding: 5px 12px;
  border-radius: 6px;
  border: 1px solid #ffffff;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.logout .btn-logout:hover {
  background: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
}

/*********responsive*************/
@media (min-width: 240px) and (max-width: 315px) {
  .serviceSec .innerSec .boxListing .box {
    width: 50% !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  form .field {
    width: 80%;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  form .field {
    width: 80%;
  }
}
.loaderSec {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 99;
}
.loaderSec .loaderImg {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.menu-trigger {
  background: #ffffff;
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-left: auto; /* Strictly for positioning */
}

.menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.menu li {
  border-bottom: 1px solid #dddddd;
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}

.menu-container {
  position: relative;
  z-index: 9999;
  display: flex;
}
.menu-container .menuTrigger {
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  outline: none;
  border: 1px solid #8b76d6;
  background: #ffffff;
  cursor: pointer;
}
.menu-container .menuTrigger i {
  color: #856BD3;
  font-size: 20px;
}
.menu-container .dropdownMenu {
  position: absolute;
  left: 0;
  right: 0;
  top: 90px;
  transform: translateY(-50%);
  width: 85%;
  margin: 0 auto;
  z-index: 99;
  background: #856BD3;
  padding: 30px 15px;
  border-radius: 5px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}
.menu-container .dropdownMenu ul li {
  padding: 15px 0px;
  border-bottom: 1px solid #9176e4;
  text-align: center;
}
.menu-container .dropdownMenu ul li:last-child {
  border-bottom: 1px solid transparent;
}
.menu-container .dropdownMenu ul li a {
  font-size: 22px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  text-transform: capitalize;
  color: #fff;
  text-align: center;
  letter-spacing: 1px;
}

.dropdownMenu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: 0.3s ease;
}

.dropdownMenu.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50%);
  transition: 0.3s ease;
}

.success-Msg {
  position: fixed;
  left: 0;
  bottom: 30px;
  right: 0;
  margin: 0 auto;
  width: 60%;
  text-align: center;
  background: #008000;
  box-shadow: 0px 4px 18px 4px rgba(9, 255, 0, 0.23);
  padding: 15px 20px;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  border-radius: 50px;
  transition: 0.3s ease-in-out;
  transform: scale(0);
  opacity: 0;
}
.success-Msg.active {
  opacity: 1;
  transform: scale(1);
}

.btn-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-link .loader {
  margin-left: 10px;
  position: relative;
}
.btn-link .loader img {
  width: 25px;
  margin-top: 5px;
}

.dropDownList {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.dropDownList.active {
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease;
}

.dropDownList.inactive {
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}/*# sourceMappingURL=Style.css.map */